import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Game, updateGame } from '../api/gameApi';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

interface FormData {
  gameName: string;
  thumbnail?: FileList;
  zipFile?: FileList;
}

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  resize: vertical;
  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
  }
`;

const EditGame: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormData>();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [description, setDescription] = useState('');
  const [game, setGame] = useState<Game | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const response = await axios.get<Game>(`${API_URL}/games/${id}`);
        const gameData = response.data;
        setGame(gameData);
        setValue('gameName', gameData.name);
        setDescription(gameData.description);
      } catch (err) {
        setError('Không thể tải thông tin game');
        console.error('Error fetching game:', err);
      }
    };

    if (id) fetchGame();
  }, [id, setValue]);

  const onSubmit = async (data: FormData) => {
    if (!id || !game) return;

    try {
      setUploading(true);
      setError(null);

      await updateGame(
        parseInt(id),
        data.gameName,
        description,
        data.thumbnail?.[0],
        data.zipFile?.[0]
      );

      navigate('/');
    } catch (err) {
      setError('Có lỗi xảy ra khi cập nhật game. Vui lòng thử lại.');
      console.error('Update error:', err);
    } finally {
      setUploading(false);
    }
  };

  if (!game) {
    return <div>Đang tải...</div>;
  }

  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Chỉnh sửa Game</h1>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label htmlFor="gameName" className="block text-sm font-medium text-gray-700 mb-1">
            Tên Game
          </label>
          <input
            id="gameName"
            type="text"
            disabled={uploading}
            {...register('gameName', { required: 'Vui lòng nhập tên game' })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {errors.gameName && (
            <p className="mt-1 text-sm text-red-600">{errors.gameName.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Mô tả Game
          </label>
          <StyledTextArea
            id="description"
            disabled={uploading}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Nhập mô tả chi tiết về game..."
          />
        </div>

        <div>
          <label htmlFor="thumbnail" className="block text-sm font-medium text-gray-700 mb-1">
            Ảnh Thumbnail Mới (không bắt buộc)
          </label>
          <input
            id="thumbnail"
            type="file"
            accept="image/*"
            disabled={uploading}
            {...register('thumbnail')}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="zipFile" className="block text-sm font-medium text-gray-700 mb-1">
            File Game Mới (không bắt buộc)
          </label>
          <input
            id="zipFile"
            type="file"
            accept=".zip"
            disabled={uploading}
            {...register('zipFile')}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
          type="submit"
          disabled={uploading}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
        >
          {uploading ? 'Đang cập nhật...' : 'Cập nhật Game'}
        </button>
      </form>
    </div>
  );
};

export default EditGame;
