import React from 'react';
import { Link } from 'react-router-dom';
import { Game } from '../api/gameApi';

interface GameItemProps {
  game: Game;
  apiUrl?: string;
}

const GameItem: React.FC<GameItemProps> = ({ game, apiUrl }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img
        src={`${apiUrl}/${game.thumbnailPath}`}
        alt={game.name}
        className="h-48 object-cover"
      />
      <div className="p-4">
        <Link to={`/game/${game.id}`}>
          <h2 className="text-xl font-semibold mb-2 hover:text-blue-500">{game.name}</h2>
        </Link>
        <div className="flex justify-between items-center">
          <span className="text-gray-600 text-sm">
            {new Date(game.createdAt).toLocaleDateString('vi-VN')}
          </span>
          <div className="space-x-2">
            <Link
              to={`/edit-game/${game.id}`}
              className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
            >
              Sửa
            </Link>

          </div>
        </div>
      </div>
    </div>
  );
};

export default GameItem;
