import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export interface Game {
  id: number;
  name: string;
  description: string;
  thumbnailPath: string;
  zipPath: string;
  createdAt: Date;
  iframePath?: string;
}

export const uploadGame = async (
  gameName: string,
  description: string,
  thumbnail: File,
  zipFile: File
): Promise<Game> => {
  try {
    const formData = new FormData();
    formData.append('name', gameName);
    formData.append('description', description);
    formData.append('thumbnail', thumbnail);
    formData.append('zipFile', zipFile);

    console.log('Đang gửi yêu cầu đến:', `${API_URL}/games`);
    const response = await axios.post<Game>(`${API_URL}/games`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Upload thành công:', response.data);
    return response.data;
  } catch (error) {
    console.error('Lỗi khi tải lên game:', error);
    throw error;
  }
};

export const fetchGames = async (): Promise<Game[]> => {
  try {
    const response = await axios.get<Game[]>(`${API_URL}/games`);
    return response.data;
  } catch (error) {
    console.error('Lỗi khi tải danh sách game:', error);
    throw error;
  }
};

export const updateGame = async (
  id: number,
  gameName: string,
  description: string,
  thumbnail?: File,
  zipFile?: File
): Promise<Game> => {
  try {
    const formData = new FormData();
    formData.append('name', gameName);
    formData.append('description', description);
    if (thumbnail) formData.append('thumbnail', thumbnail);
    if (zipFile) formData.append('zipFile', zipFile);

    const response = await axios.patch<Game>(`${API_URL}/games/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Lỗi khi cập nhật game:', error);
    throw error;
  }
};
