import React from 'react';
import { Article as ArticleType } from '../api/articleApi';

interface ArticleProps {
  article: ArticleType;
  onDelete: (id: string) => void;
}

const sanitizeHtml = (html: string): string => {
  return html.replace(/<(?!\/?(b|p|i|u|br)(?=>|\s.*>))\/?.*?>/gi, '');
};

const Article: React.FC<ArticleProps> = ({ article, onDelete }) => {
  return (
    <div className="bg-white mb-4 relative flex flex-col">
      <div className="flex-1">
        <div className="flex justify-end mb-2">
          <button
            onClick={() => onDelete(article.id.toString())}
            className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
          >
            Xóa
          </button>
        </div>
      </div>

      <div className="flex-1">
      <h2 className="text-2xl font-bold mb-2">{article.title}</h2>
      <div
        className="mb-4 text-gray-700"
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(article.content) }}
      />
      <a
        href={article.url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline mb-2 inline-block"
      >
        Đọc thêm
      </a>
      <p className="text-sm text-gray-500 italic">
        Ngày đăng: {new Date(article.publishedAt).toLocaleDateString()}
      </p>
      </div>
    </div>
  );
};

export default Article;
