import React from 'react';
import { Link } from 'react-router-dom';
import { Article } from '../api/articleApi';

interface RecentArticlesProps {
  articles: Article[];
}

const RecentArticles: React.FC<RecentArticlesProps> = ({ articles }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <Link to="/articles" className="text-xl font-bold hover:bg-gray-50 hover:text-blue-500 mb-4">Danh sách bài viết</Link>
      <div className="space-y-4">
        {articles.slice(0, 10).map((article) => (
          <Link
            key={article.id}
            to={article.url}
            target="_blank"
            className="flex items-center space-x-3 hover:bg-gray-50 p-2 rounded"
          >
            <div>
              <h3 className="font-medium hover:text-blue-500">{article.title}</h3>
              <p className="text-sm text-gray-500">
                {new Date(article.publishedAt).toLocaleDateString('vi-VN')}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RecentArticles;
