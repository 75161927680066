import React, { useEffect, useState } from 'react';
import { Game } from '../api/gameApi';
import axios from 'axios';
import GameItem from '../components/GameItem';
import RecentArticles from '../components/RecentArticles';
import { Article } from '../api/articleApi';

const API_URL = process.env.REACT_APP_API_URL;

const GameList: React.FC = () => {
  const [games, setGames] = useState<Game[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get<Game[]>(`${API_URL}/games`);
        setGames(response.data);
      } catch (err) {
        setError('Có lỗi xảy ra khi tải danh sách game');
        console.error('Error fetching games:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchArticles = async () => {
      try {
        const response = await axios.get<Article[]>(`${API_URL}/articles`);
        setArticles(response.data);
      } catch (err) {
        setError('Có lỗi xảy ra khi tải danh sách bài viết');
        console.error('Error fetching articles:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
    fetchArticles();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
        {error}
      </div>
    );
  }

  // Sắp xếp games theo thời gian tạo mới nhất
  const sortedArticles = [...articles].sort((a, b) =>
    new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
  );

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl font-bold mb-6">Danh sách Game</h1>
      <div className="flex flex-col md:flex-row gap-6">
        <div className="md:w-8/12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {games.map((game) => (
              <GameItem key={game.id} game={game} apiUrl={API_URL} />
            ))}
          </div>
        </div>
        <div className="md:w-4/12">
          <RecentArticles articles={sortedArticles} />
        </div>
      </div>
    </div>
  );
};

export default GameList;
