import React, { useEffect, useState } from 'react';
import { fetchArticles, crawlArticles, deleteArticle, Article as ArticleType } from '../api/articleApi';
import Article from './Article';
import styled from 'styled-components';

const ArticleListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArticleWrapper = styled.div`
  margin-bottom: 20px; // Tạo khoảng cách 20px giữa các article
  background-color: #ffffff; // Thêm nền trắng cho mỗi bài viết
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ArticleList: React.FC = () => {
  const [articles, setArticles] = useState<ArticleType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const loadArticles = async () => {
    try {
      const fetchedArticles = await fetchArticles();
      setArticles(fetchedArticles);
      setLoading(false);
    } catch (err) {
      setError('Có lỗi xảy ra khi tải bài viết');
      setLoading(false);
    }
  };

  useEffect(() => {
    loadArticles();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    try {
      await crawlArticles();
      await loadArticles();
    } catch (err) {
      setError('Có lỗi xảy ra khi cập nhật bài viết');
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteArticle(id);
      setArticles(articles.filter(article => article.id !== id));
    } catch (err) {
      setError('Có lỗi xảy ra khi xóa bài viết');
    }
  };

  if (loading) return <div>Đang tải...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl font-bold mb-4">Danh sách bài viết</h1>
      <button
        onClick={handleRefresh}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
      >
        <svg
          className="w-5 h-5 inline-block mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
        Cập nhật
      </button>
      <ArticleListContainer>
        {articles.map((article) => (
          <ArticleWrapper key={article.id}>
            <Article article={article} onDelete={() => handleDelete(article.id)} />
          </ArticleWrapper>
        ))}
      </ArticleListContainer>
    </div>
  );
};

export default ArticleList;
