import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { uploadGame } from '../api/gameApi';
import { useNavigate } from 'react-router-dom';

interface FormData {
  gameName: string;
  description: string;
  thumbnail: FileList;
  zipFile: FileList;
}

const FormContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  resize: vertical;
  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
  }
`;

const UploadGame: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  const onSubmit = async (data: FormData) => {
    try {
      setUploading(true);
      setError(null);

      await uploadGame(
        data.gameName,
        description,
        data.thumbnail[0],
        data.zipFile[0]
      );

      navigate('/');
    } catch (err) {
      setError('Có lỗi xảy ra khi tải lên game. Vui lòng thử lại.');
      console.error('Upload error:', err);
    } finally {
      setUploading(false);
    }
  };

  return (
    <FormContainer>
      <h1 className="text-3xl font-bold mb-6">Tải lên Game mới</h1>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label htmlFor="gameName" className="block text-sm font-medium text-gray-700 mb-1">
            Tên Game
          </label>
          <input
            id="gameName"
            type="text"
            disabled={uploading}
            {...register('gameName', { required: 'Vui lòng nhập tên game' })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {errors.gameName && (
            <p className="mt-1 text-sm text-red-600">{errors.gameName.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Mô tả Game
          </label>
          <StyledTextArea
            id="description"
            disabled={uploading}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Nhập mô tả chi tiết về game..."
          />
        </div>

        <div>
          <label htmlFor="thumbnail" className="block text-sm font-medium text-gray-700 mb-1">
            Ảnh Thumbnail
          </label>
          <input
            id="thumbnail"
            type="file"
            accept="image/*"
            disabled={uploading}
            {...register('thumbnail', { required: 'Vui lòng chọn ảnh thumbnail' })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {errors.thumbnail && (
            <p className="mt-1 text-sm text-red-600">{errors.thumbnail.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="zipFile" className="block text-sm font-medium text-gray-700 mb-1">
            File Zip
          </label>
          <input
            id="zipFile"
            type="file"
            accept=".zip"
            disabled={uploading}
            {...register('zipFile', { required: 'Vui lòng chọn file zip' })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {errors.zipFile && (
            <p className="mt-1 text-sm text-red-600">{errors.zipFile.message}</p>
          )}
        </div>

        <button
          type="submit"
          disabled={uploading}
          className={`w-full px-4 py-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-400
            ${uploading
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
            }`}
        >
          {uploading ? 'Đang tải lên...' : 'Tải lên'}
        </button>
      </form>
    </FormContainer>
  );
};

export default UploadGame;
