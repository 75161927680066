import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export interface Article {
  id: number;
  title: string;
  url: string;
  content: string;
  publishedAt: Date;
}

export const fetchArticles = async (): Promise<Article[]> => {
  try {
    console.log('Đang gửi yêu cầu đến:', `${API_URL}/articles`);
    const response = await axios.get<Article[]>(`${API_URL}/articles`);
    console.log('Dữ liệu nhận được:', response.data);
    return response.data;
  } catch (error) {
    console.error('Lỗi khi tải bài viết:', error);
    throw error;
  }
};

export const crawlArticles = async (): Promise<void> => {
  try {
    await axios.post(`${API_URL}/articles/crawl`);
  } catch (error) {
    console.error('Lỗi khi crawl bài viết:', error);
    throw error;
  }
};

export const deleteArticle = async (id: number): Promise<void> => {
  try {
    console.log('Đang gửi yêu cầu xóa bài viết:', `${API_URL}/articles/${id}`);
    await axios.delete(`${API_URL}/articles/${id}`);
    console.log('Bài viết đã được xóa thành công');
  } catch (error) {
    console.error('Lỗi khi xóa bài viết:', error);
    throw error;
  }
};
