import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Game } from '../api/gameApi';

const API_URL = process.env.REACT_APP_API_URL;

const GameDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [game, setGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const response = await axios.get<Game>(`${API_URL}/games/${id}`);
        const gameData = response.data;
        setGame(gameData);
      } catch (err) {
        setError('Không thể tải thông tin game');
        console.error('Error fetching game:', err);
      } finally {
        setLoading(false);
      }
    };

    if (id) fetchGame();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !game) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
        {error || 'Không tìm thấy game'}
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <div className="aspect-w-16 aspect-h-9 mb-6">
            <iframe
              src={game.iframePath}
              className="w-full h-[750px] border-0"
              title={game.name}
              allowFullScreen
            />
          </div>

          <h1 className="text-3xl font-bold mb-4">{game.name}</h1>
          <div className="mb-6 text-gray-600 whitespace-pre-wrap">
            {game.description}
          </div>

          <div className="flex justify-between items-center">
            <span className="text-gray-600">
              Ngày đăng: {new Date(game.createdAt).toLocaleDateString('vi-VN')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameDetail;
